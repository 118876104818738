.trash {
    color:rgb(228, 28, 2);
}


.hidden {
    display: none;
}

.table {
    font-size: 1rem;
}

.table>tbody>tr>td>div>input {
    padding: 0px 0px 0px 4px;
    margin: 0px;
    font-size: 1rem;
    height: 20px;
}

.table>tbody>tr>td>div>select {
    padding: 0px 0px 0px 4px;
    margin: 0px;
    font-size: 1rem;
    height: 20px;
}

.table>tbody>tr>td>div {
    padding: 0px !important;
    margin: 0px;
}

.containerNumber {
    width: 174px;
}

.sealNumber {
    width: 174px;
}

.weight {
    width: 100px;
}

.unitOfMeasurement {
    width: 100px;
}

.count {
    width: 68px;
}

.unit {
    width: 96px;
}

.cubicMeters {
    width: 100px;
}

.hsCode {
    width: 100px;
}

.description {
    width: auto;
}

.price {
    width: 80px;
}

.toast {
    border: 1px black solid;
    background-color: #444;
    border-radius: 4px;
}

.successToast {
    padding: 6px;
    min-width: 200px;
    background-color: #444;
    color: #4caf50;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 1em;
    font-weight: 500;
}

.errorToast {
    padding: 6px;
    min-width: 200px;
    background-color: #444;
    color: #f44336 ;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 1em;
    font-weight: 500;
}

.shipmentIcon:hover{
    color: #2196f3;
    cursor: pointer;
}

.delete{
    color: #f44336;
    position: relative;
    top: 3px;
}

.delete:hover{
    border-radius: 3px;
    background-color: #f44336;
    color: white;
}

.containerSelected{
    background-color: #2196f3;
}

.hide{
    display:none;
}

i, td, th{
    cursor: pointer;
}

.bookingNumber{
    padding: 0px;
    margin: 0px;
    font-size: 1.2em;
}

/* .sticky{
    position: sticky;
    width: 100%;
    top: 112px;
}

.sticky>th{
    width: 100%;
} */

.notes {
    color: grey;
    font-style: italic;
}