* {
    margin: 0px;
    padding: 0px;
    border: none;
}

body {
    background-color: #fff;
    height: 100vh;
}

.container {
    margin-top: 12px;
    background-color: white;
    box-shadow: black 0px 2px 6px;
    border-radius: 2px;
}

#root {
    height: 100vh;
}

.body {
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sidebar {
    background-color: white;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
}

.content {
    flex: 6;
    height: 100%;
    box-shadow: -2px 0px 5px #aaa;
    z-index: 1;
}

.collection {
    margin: 0px;
    padding: 0px;
    max-width: 100%;
    height: 100%;
}