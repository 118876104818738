.div {
    min-height: 20px;
    border-radius: 5px;
    margin-bottom: 10px!important;
    display: flex!important;
    justify-content: flex-start!important;
    flex-direction: row!important;
    flex-wrap: wrap!important;
    position: relative;
    background-color: white!important;
    border: #9e9e9e solid 1px!important;
}
.div:hover {
    border: 1px solid black!important;
}

.container {
    flex: 11;
    max-width: 100%;
}

.open {
    background-color: white;
    width: calc(100% - 12px);
    border: solid 1px #ddd;
    z-index: 100;
    position: absolute;
}

.list {
    padding: 6px !important;
    width: max-content;
}

.suggestion {
    font-size: 12px;
    padding: 5px;
    width: max-content;
}

[role="listbox"] {
    width: max-content;
}

.smallFormList {
    font-size: 12px!important;
}

.input {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border-radius: 3px;
    height: 30px!important;
    outline: none;
    font-size: 1.15rem;
    width: 99%;
    min-width: 130px;
}

.smallFormInput {
    font-size: 12px!important;
    height: 20px!important;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-bottom: 0px solid black;
    border-radius: 3px;
    outline: none;
    width: 100%;
}

.label {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.msg {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.item {
    font-size: 1.15rem;
    left: 6px;
}

.highlighted {
    background-color: #2196f3;
    color: white;
}

.add {
    margin: 0px;
    padding: 0px;
    color: #2196f3;
    background-color: white;
    font-size: 32px;
    border-radius: 6px;
    flex: 1;
}

.add:focus {
    background-color: white;
    color: #2196f3;
    border: #2196f3 1px solid;
    margin-left: 3px;
}

.emailAutocompleteText  {
    background-color: red!important
}

.span {
    background-color: #33679e!important;
    color: white;
    border-radius: 4px;
    max-height: 23px!important;
    padding: 0px 19px 5px 4px;  
    margin: 3px 3px 3px 3px;
}

.invalidEmail {
    background-color: #f02603!important;
    color: white;
    border-radius: 4px;
    max-height: 23px!important;
    padding: 0px 19px 5px 4px;  
    margin: 3px 3px 3px 3px;
}

.icon {
    color:white!important;
    margin-top: 5px;
    margin-left: 2px;
    position:absolute

}