.modal {
  overflow: hidden;
}

.modal-action {
  padding-left: 20px;
  margin-top: -30px;
}

.lf-portal-table {
  background-color: white;
  font-weight: 400;
  font-size: 0.9em;
}

.lf-portal-thead {
  background-color: #33679e;
  color: white;
  max-height: 40px;
  line-height: 1;
}

.lf-portal-thead > tr > th {
  position: relative;
  border-radius: 0px;
  text-align: left !important;
  font-size: 1em;
  max-height: 40px;
}

.lf-portal-tbody > tr > td {
  border-radius: 0px;
  text-align: left;
}

.lf-portal-tbody > tr {
  margin: 2px;
  border: 1px solid white;
}

.lf-portal-tbody > tr:hover {
  background-color: #eee;
}

.lf-portal-tbody > tr.selected {
  color: white;
  background-color: #2196f3;
  border: 1px solid white;
}

.lf-portal-tbody > tr.menu-selected {
  color: white;
  background-color: rgb(255, 174, 69);
  border: 1px solid white;
}

.lf-portal-tbody > tr > td {
  margin: 0px;
  padding: 0px;
  padding: 5px 5px 5px 12px;
}

.lf-shipment-context-menu > ul > li:hover {
  background-color: #ddd;
}

.lf-portal-tbody > tr > td.no-margin-no-padding {
  margin: 0px;
  padding: 0px;
  width: 1px !important;
}

.lf-portal-tbody > tr > td.no-margin-no-padding > span.badge {
  margin: 4px;
  padding: 0px;
}

span.badge.new:after {
  content: none;
}

.connection:nth-child(even){
  background-color: #f0f3f7;
  
}

.connection{
  font-size: 18px;
}

.currentParty{
  font-size:18px;
}

.clientConnections{ 
  margin-left: 5px !important;
  width: calc(50% - 5px)!important;
}

.addConnection{
  margin-right: 5px !important;
  width: calc(50% - 5px)!important;
}

.useAlternateSubjectLine {
  margin-top: -4px;
  margin-left: -10px;
  font-size: 12px!important;
}