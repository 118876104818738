.container {
    min-height: 28px;
    border-radius: 5px;
    margin-bottom: 10px!important;
    display: flex!important;
    justify-content: flex-start!important;
    flex-direction: row!important;
    flex-wrap: wrap!important;
    position: relative;
}

.span {
    height: 15px!important;
    padding: 0px 23px 22px 8px!important;
    margin-top: 2px;
    margin-right: 5px;
    color: white;
    border-radius: 5px;
}

.icon {
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
}

.shipperName {
    background-color: rgb(14, 55, 69)!important;
}

.carrierName {
    background-color: rgb(176, 53, 16)!important;
}

.status {
    background-color:  rgb(48, 85, 95)!important;
}

.originCode {
    background-color: rgb(16, 139, 176)!important;
}

.destCode { 
    background-color: rgb(95, 58, 48)!important;
}

.consigneeName {
    background-color: rgb(14, 55, 69)!important;
}

.vesselName {
    background-color: rgb(48, 84, 150)!important;
}

.voyageNumber {
    background-color: rgb(48, 84, 150)!important;
}

.serviceRoute {
    background-color: rgb(105, 127, 133)!important;
}

.pickupLocation {
    background-color: rgb(0, 133, 0);
}

.returnLocation {
    background-color: rgb(1, 157, 1);
}

.portOfLoad {
    background-color: rgb(64, 186, 64);
}