.div {
    margin: 0px;
    padding-right: 0px!important;
    position: relative;
}

.input {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 2px;
    padding-right: 0px!important;
    background-color: white;
    border: #9e9e9e solid 1px;
    height: 22px;
    outline: none;
    font-size: 12px;
    width: 100%;
}

.input.err {
    border: 1px solid #f44336;
}

.input.err:hover {
    border: 1px solid #f44336;
}

.input.err:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.input:hover {
    outline: solid 1px black;
}

.input:focus {
    outline: 1px solid #2196f3!important;
    box-shadow:0px 0px 0px 1px #2196f3 inset;
}

.label {
    height:16px;
    margin: 0px;
    padding: 0px 0px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.msg {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.disabled{
    background-color: #eee;
}
