.table {
    background-color: white;
    font-weight: 400;
    font-size: .9em;
}

.thead {
    background-color: #33679e;
    color: white;
    max-height: 36px;
    line-height: .5;
}

.thead>tr>th {
    position: relative;
    border-radius: 0px;
    text-align: center;
    font-size: 1em;
    max-height: 40px;
}

.thead>tr>th>span {
    position: absolute;
    background-color: white;
    width: 1px;
    height: 22px;
    top: 7px;
    right: -1px;
}

.tbody>tr>td {
    border-radius: 0px;
    text-align: left;
}

.tr {
    margin: 2px;
    border: 1px solid white;
}

.tbody>tr:hover {
    background-color: #ddd;
}

.tbody>tr.selected {
    color: white;
    background-color: #2196f3;
    border: 1px solid white;
}

.tbody>tr.menu-selected {
    color: white;
    background-color: rgb(255, 174, 69);
    border: 1px solid white;
}

.tbody>tr>td {
    margin: 0px;
    padding: 0px;
    padding: 5px 5px 5px 12px;
}

.context-menu>ul>li:hover {
    background-color: #eee;
}

.tbody>tr>td.no-margin-no-padding {
    margin: 0px;
    padding: 0px;
    width: 1px !important;
}

.tbody>tr>td.no-margin-no-padding>span.badge {
    margin: 4px;
    padding: 0px;
}

.tbody>tr>td>span.badge.new:after {
    content: none;
}