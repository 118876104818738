.div {
    position: relative;
    padding-right: 12px;
    height: 30px;
    width: 100%;
}

.innerDiv {
    height: 36px;
    width: auto;
    padding: 0px 0px;
    margin-left: -10px;
    border-radius: 4px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 20px;
    padding-bottom: 5px;
}

.innerDiv:hover {
    width: auto;
}

.span {
    font-size: 14px !important;
    padding-left: 30px !important;
    padding-top: -10px !important;
}

.span:hover {
    background-color: rgb(212, 212, 212)
}

.label {
    transform: scale(.8);
    width: auto !important;
    max-width: auto !important;
}

.p {
    height: 100%;
    width: auto;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    flex-direction: column;
}

.span {
    font-size: 14px !important;
    padding-left: 30px !important;
}

.span:hover {
    background-color: rgb(212, 212, 212);
}

.label {
    transform: scale(.8)
}

input {
    will-change: transform;
}