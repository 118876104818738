.blue:hover{
    border: 2px rgb(143, 188, 255) outset;
    cursor: pointer
}

.red:hover{
    border: 2px rgb(255, 156, 143) outset;
    cursor: pointer;
}

.modal{
    cursor:default
}