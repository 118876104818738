/* GENERAL STYLING */
body {
  overflow: hidden !important;
}

.reduxLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.reduxLoader>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 200px;
}

.reduxLoader>div>div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.reduxLoader>div>div>.preloader-wrapper {
  height: 200px;
  width: 200px;
}

#master-content {
  overflow-y: scroll;
  border-top: none !important;
  box-shadow: -2px 3px 5px #aaa !important;
}

nav {
  box-shadow: none;
  border-bottom: 1px solid #e9e9e9;
  background-color: white;
}

.lf-portal-table {
  background-color: white;
  font-weight: 400;
  font-size: 0.9rem;
}

.lf-portal-table-shipment-overview {
  background-color: white;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline-block !important;
}

.resultsDisplay {
  font-size: 0.8rem;
}

.resultsDisplay:focus,
.resultsDisplay:active,
.exdecResultsDisplay:focus,
.exdecResultsDisplay:active {
  margin-left: -5px;
  border-radius: 5px;
  outline: solid #2196f3 !important;
}

.portOfLoadColumn {
  color: lightgrey;
}

.exdecResultsDisplay {
  background-color: #e9e9e9;
}

@media (min-width: 100px) {
  .resultsDisplay {
    font-size: 0.75rem;
  }
}

@media (max-width: 1000px) {
  .lf-portal-tbody>tr>td>i {
    width: 14px;
  }
}

@media (min-width: 1200px) {
  .resultsDisplay {
    font-size: 0.9rem;
  }
}

.lf-portal-thead {
  background-color: #33679e;
  color: white;
  max-height: 40px;
  line-height: 1;
  min-width: 2000px !important;
  max-width: 10px !important;
}

.lf-portal-thead>tr>th {
  position: relative;
  border-radius: 0px;
  text-align: center;
  font-size: 1em;
  max-height: 40px;
  border-right: 1px solid white;
}

.lf-portal-thead>tr>th>span {
  width: 1px;
  height: 30px;
}

.lf-portal-tbody>tr>td {
  border-radius: 0px;
  text-align: left;
}

.lf-portal-tbody>tr {
  margin: 2px;
  border: 1px solid white;
}

.lf-portal-tbody>tr:hover {
  background-color: #eee;
}

.lf-portal-tbody>tr.selected {
  color: white;
  background-color: #2196f3;
  border: 1px solid white;
}

.lf-portal-tbody>tr.menu-selected,
.week-list-tbody>tr.menu-selected {
  color: white !Important;
  background-color: rgb(255, 174, 69) !important;
  border-left: 1px rgb(255, 174, 69) solid !important;
}

.lf-portal-tbody>tr>td {
  margin: 0px;
  padding: 0px;
  padding: 0px 3px 0px 3px !important;
}

.lf-shipment-context-menu {
  z-index: 99999;
}

.lf-shipment-context-menu>ul>li:hover {
  background-color: #ddd;
}

.lf-portal-tbody>tr>td.no-margin-no-padding {
  margin: 0px;
  width: 1px !important;
}

.lf-portal-tbody>tr>td.no-margin-no-padding>span.badge {
  margin: 4px;
}

.sidebar-collection-header {
  margin-top: -25px !important;
  padding-top: 0px;
}

.sidebar-collection {
  border: none !important;
}

.menu-selected>td {
  border-bottom: white !important;
}

/* BOOKING CREATION STYLING */

.emailBadge {
  color: rgb(0, 0, 0) !important;
  border: 1px black solid;
  font-weight: 700 !important;
}

.badgeCol {
  margin-top: 3px;
  text-align: center;
}

.bccButton {
  margin-bottom: 0px;
}

span.badge.new:after {
  content: none;
}

#logModal {
  width: 60%;
  overflow: auto;
  border-radius: 10px;

}

#emailModal,
#fileUploadEmailModal {
  margin-top: 50px;
  width: 70%;
  padding: 20px;
  padding-bottom: 100px !important;
  height: 70% !important;
  overflow: auto;
  max-height: 100%;
}

#docsDueEmailModal {
  margin-top: 50px;
  width: 70%;
  padding: 20px;
  height: 70% !important;
  overflow: auto;
  max-height: 100%;
}

#excelEmailModal {
  overflow-y: scroll;
}

.email-modal {
  padding-top: 0px !important;
}

.pdfViewer {
  width: 100% !important;
}

.tox-notification {
  display: none !important;
}


#emailButton {
  z-index: 10;
}


.useChangesSwitch {
  z-index: 10;
}


.pdfViewer {
  display: flex;
  justify-content: center;
  margin-top: -15px;
  overflow: visible !important;
  position: relative;
}

.pdf-loader {
  height: 100px !important;
  width: 100px !important;
  margin-top: 200px !important;
}

#displayWindow {
  width: 820px;
  height: 980px;
}

.pdfLoading {
  text-align: center;
}

@media(max-width: 1100px) {
  #logModal {
    width: 85%;
  }
}

.changeLogDisplay {
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.changedFromBooking {
  background-color: yellow !important;
}

.changeLogTableHeader {
  text-align: left !important;
}

.s {
  border: 0px black solid;
  padding: 0px;
  margin: 0px;
  border-spacing: 0px !important;
}

.changeLogDisplay>thead {
  background-color: rgb(175, 175, 175);
}

.changeLogDisplay>tbody>tr>td {
  padding: 5px !important;
}

.even {
  background-color: rgb(221, 221, 221) !important;
}

.odd {
  background-color: white !important;
}

.changeLogDisplay>tbody>tr:hover {
  background-color: #e4f5ff !important;
}

.vvSpinner {
  text-align: center;
  padding-top: 40px;
  margin-right: 10px;
  transform: scale(1.5);
}

span.badge.new:after {
  content: none;
}

.collapsible-body {
  padding-bottom: 0px;
}

.bookingBadge {
  position: absolute;
  margin-left: 25px !important;
  margin-top: 0px;
}

.SIbadge {
  position: absolute;
  left: -80px !important;
  top: 5px;
  z-index: 1;
}

.SIstage {
  position: absolute;
  left: -80px !important;
  top: 10px;
  z-index: 1;
}

.badgeHolder {
  margin-top: 3px;
  margin-right: 45px;
  margin-left: 0px;
  padding-left: 0px !important;
}

.badeHolderStageIcon {
  margin-top: -1px;
  margin-left: 0px;
  padding-left: 0px !important;
}

.ic {
  margin-top: -6px !important;
}

.timeZoneDisplay {
  font-size: 12px;
  margin-left: -10px !important;
  padding-top: 9px !important;
  color: grey;
}

#vessel-add-autocomplete {
  background-color: red;
}

.badge {
  box-sizing: border-box;
  background-color: white !important;
  padding: 0px 0px 20px 0px !important;
  border-radius: 10%;
  font-size: 13px !important;
  font-family: helvetica;
}

#closedBadge {
  color: rgb(100, 100, 100);
  border: 1px rgb(100, 100, 100) solid;
}

#activeBadge {
  color: green;
  border: 1px rgb(3, 168, 3) solid;
}

#cancelBadge {
  color: rgb(255, 0, 0);
  border: 1px rgb(255, 0, 0) solid;
}

#archiveBadge {
  color: black;
  border: 1px black solid;
}

#pendingBadge {
  color: purple;
  border: 1px purple solid;
}

.cancelStamp {
  color: rgb(122, 122, 122);
  letter-spacing: 20px;
  font-size: 150px;
  opacity: 0.3;
  position: absolute;
  top: 35%;
  left: 20%;
  z-index: 1;
  transform: rotate(-30deg);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

.closedStamp {
  color: rgb(122, 122, 122);
  letter-spacing: 20px;
  font-size: 150px;
  opacity: 0.3;
  position: absolute;
  top: 35%;
  left: 35%;
  z-index: 1;
  transform: rotate(-30deg);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

.bookingLoader {
  position: absolute;
  left: 55%;
  top: 50%;
}

.names {
  font-size: 16px;
  float: right !important;
  overflow: hidden;
  letter-spacing: -1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.userInfo {
  padding: 1px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  width: 100%;
}

.classTitle {
  font-size: 16px;
  float: left !important;
  overflow: hidden;
}

.internalNotes {
  width: 103% !important;
}

.floatContainer {
  overflow: auto;
  font-family: "Lucida Console";
}

.nameTitle {
  font-size: 16px;
  float: left !important;
  overflow: hidden;
  z-index: 2;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.colon {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-right: 3px;
  float: left;
}

.addNotesHeader {
  max-height: 10px;
  padding-top: 0px;
  padding-bottom: 25px;
  margin-top: -20px;
  margin-bottom: 0px;
  background-color: #f3f3f3 !important;
  border: 2px #f3f3f3 solid !important;
  border-radius: 5px;
}

.addNotesHeader:focus {
  border-radius: 5px;
  border: 2px #2196f3 solid !important;
}

.additionalNotes {
  height: 110px;
  width: 100%;
  padding: 0px;
  margin-bottom: -10px;
  box-shadow: none !important;
  border: none !important;
}

.additionalNotesIcon {
  float: left;
  margin-left: -20px;
}

.createBookingWrapper {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  position: relative;
  overflow: hidden !important;
  background-color: #f3f3f3;
  padding: 6px;
  border-radius: 3px;
  ;
}

.cBar {
  margin-bottom: -10px;
  box-shadow: none !important;
  border: 0px black solid;
  background-color: #f3f3f3 !important;
}

.buttonsRow {
  max-height: 38px;
  margin-bottom: 0px !important;
}

.bookingShipperDropdown {
  background-color: #f3f3f3 !important;
  font-weight: 500 !important;
  border: none !important;
}

.dummy {
  background-color: #f3f3f3;
  font-weight: 500;
  border: none;
  color: #9c27b0 !important;
}

.noValue {
  background-color: white !important;
  border: 1px darkgrey solid !important;
}

#date-warning-modal {
  background-color: darkred;
  color: white;
  border: 2px white solid;
  box-sizing: border-box;
}

.useDisplayNameCheckbox {
  width: 42px !important;
  height: 32px;
  margin-right: -10px;
}

.shipperId {
  margin-right: -10px !important;
}

.checkbox_p__1RAAq:active,
.checkbox_span__eUvpF:active,
.useDisplayNameCheckbox:active,
.useDisplayNameCheckbox:focus {
  border: 0px solid black !important;
}

.hideDisplayName {
  background-color: whitesmoke;
  color: whitesmoke !important;
  border: 0px solid whitesmoke;
}

.hideDisplayName:hover,
.hideDisplayName:focus,
.hideDisplayName:active {
  border: 0px !important;
}

.bookingsEmployee {
  margin-top: 15px;
}

.bookingHeaderInfo {
  text-align: center;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  padding-top: 6px !important;
}

.subscribeCheckbox>div {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.subscribeCheckbox>div>p {
  flex-direction: row !important;
  align-items: center !important;
  align-content: flex-start !important;
  justify-content: flex-start !important;
}

.subscribeCheckbox>div>p>label {
  margin-left: -17px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.target {
  border-bottom: none !important;
}

.vesselVoyageBookingCountRow>div {
  text-align: center;
  font-weight: 600;
}

.flagIconHolder {
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
}

/* SHIPMENT OVERVIEW STYLING */

.activePage {
  color: white;
  background-color: #33679e;
}

.pagination>li {
  max-height: 22px;
}

.pageLink {
  margin-top: 3px;
}

.pagination {
  border-bottom: 0px !important;
  width: auto !important;
  position: absolute;
  bottom: 10px;
  left: 45%;
}

.resultsCount {
  position: absolute;
  bottom: 10px;
  left: 32%;
  z-index: 1;
}

.overviewCollapsibleBody {
  margin: 0px !important;
  padding-left: 12px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 15px !important;
}

.containerResultsCount {
  position: absolute;
  bottom: 10px;
  left: 15%;
  z-index: 1;
}

.overviewLoader {
  position: absolute;
  left: 50%;
  top: 50%;
}

.overviewCollapsible {
  padding: 10px !important;
}

.search-field {
  margin-bottom: 10px !important;
}

.search-wrapper {
  margin-top: 22px;
}

.searchOpenIcon {
  margin-top: -5px;
}

.dummyPurple {
  color: #9c27b0;
}

.lf-chart-context-menu>ul>li:hover {
  background-color: #ddd;
  cursor: pointer;
}

.lf-chart-context-menu>ul>li {
  border: 1px solid #33679e;
  padding: 10px;
}

#chart-alert {
  position: absolute;
  left: 33%;
  top: 50%;
  font-size: 1.5vw;
  font-family: Arial, Helvetica, sans-serif;
  display: none;
}

.barWrapper {
  position: relative;
}

#submitInitialsBadge,
#bookingsInitialsBadge {
  color: black;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  width: 20px !important;
}

.exdecSwitch {
  padding: 0px !important;
  margin-top: -10px !important;
}

.tooltip:hover {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 15px 0px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptextleft {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 0px 15px;
}

.tooltip:hover .tooltiptextleft {
  visibility: visible;
}

.tooltip .tooltiptextdate {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  left: 50px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 15px 0px;
}

.tooltip:hover .tooltiptextdate {
  visibility: visible;
}

.containerCountTooltip {
  position: relative;
}

.containerCountTooltip .containerCountTooltipText {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  left: 10px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 15px 0px;
}

.containerCountTooltip:hover .containerCountTooltipText {
  visibility: visible;
}

.delayedTooltip {
  position: relative;
}

.delayedTooltip:after {
  opacity: 0;

}

.delayedTooltip .tooltiptext {
  opacity: 0;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  right: 20px;
  bottom: 35px;
  width: max-content;
  z-index: 4000 !important;
  border-radius: 15px 15px 0px 15px;
  pointer-events: none;
}

.delayedTooltip:hover .tooltiptext {
  opacity: 1;
  transition: opacity 0s linear 1s;
}

.customerDelayedTooltip {
  position: relative;
}

.customerDelayedTooltip:after {
  opacity: 0;

}

.customerDelayedTooltip .tooltiptext {
  opacity: 0;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  right: 60px;
  bottom: 10px;
  width: max-content;
  z-index: 4000 !important;
  border-radius: 15px 15px 0px 15px;
  pointer-events: none;
}

.customerDelayedTooltip:hover .tooltiptext {
  opacity: 1;
  transition: opacity 0s linear 1s;
}

#hiddenTabs {
  visibility: hidden;
  height: 35px;
}

.customerName {
  font-weight: 800;
}

#hiddenJsonUploadButton {
  display: none;
}


/* SHIPMENT INSTRUCTIONS STYLING */
#alias-modal {
  text-align: center;
  width: 25%;
  overflow: auto;
  overflow-x: hidden;
}

.aliasCards {
  cursor: pointer;
  max-height: 50px;
  min-width: 100%;
  float: left;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 5px;
  border-radius: 10px;
  padding-top: 5px;
  padding-left: 10px;
  text-align: left;
}

#newAlias {
  margin-top: 5px;
  max-width: 90%;
  margin-left: 10%;
}

#addAliasIcon {
  float: left;
  margin-top: 10px;
  color: green;
}

#deleteAliasIcon {
  float: right;
  margin-top: -23px !important;
}

#deleteAliasIcon:active {
  color: red;
  transform: scale(1.2);
}

.infoIcon {
  width: 15px;
}

.infoIcon:hover {
  color: rgb(139, 97, 255);
}

.noAliasPlaceholder {
  margin-bottom: 25px !important;
}

#alias-modal>.modal-content>h4 {
  text-align: center !important;
}


.aliasDisplay,
.aliasDisplay:hover {
  background-color: rgb(255, 255, 255);
  border: 0px;
  font-weight: 500;
  font-size: 15px;
  font-style: italic;
}

.noAddressCard {
  text-align: center;
  font-size: 20px;
  padding: 15px;
  margin-top: 50px;
}

.addressCardHolder {
  text-align: center;
  border-radius: 20px;
  margin-right: 30px;
}

#address-modal>.modal-content {
  text-align: center;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: -10px !important;
}

#addressModalButton {
  margin-right: 10px;
  margin-bottom: -10px !important;
}

.addressOption {
  height: 150px;
  width: 250px;
}

.cutOffs {
  height: 18px;
  font-size: 12px;
}

.bookingDetails {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background-color: #f3f3f3 !important;
}

.infoIcon {
  width: 15px;
  margin-left: 4px;
}

.checkBox {
  margin: 0px !important;
}

.siCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after,
.printCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #0b78c4 !important;
  background-color: #0b78c4 !important;
}

.dummyCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #9c27b0 !important;
  background-color: #9c27b0 !important;
}

.exporterCheckBox {
  margin-top: 15px !important;
}

.consigneeCheckBox {
  margin-top: -5px !important;
}

.dummySICheckbox {
  margin-left: 85px;
  margin-top: -5px;
}

.useAliasCheckbox {
  max-width: 100px;
}

.printSiCheckbox,
.printSiCheckbox:active,
.printSiCheckbox:hover,
.printSiCheckbox:focus,
.printSiCheckbox:checked,
.printSiCheckbox:target,
.printSiCheckbox::selection {
  width: 200px !important;
}

.conIsSame {
  margin-right: 10px !important;
  margin-top: -35px !important;
}

.ckScootLeft {
  margin-left: -10px !important;
}

.rowDropper {
  margin-top: 15px !important;
}

.bumpRight {
  margin-left: 10px !important;
}

.rowMarginRemove {
  margin: 0px !important;
  margin-bottom: 5px !important;
}

.noMargin {
  margin: 0px !important;
}

.customMarginRemove {
  margin-bottom: 0px !important;
}

.exportTuck {
  margin-top: -10px !important;
}

.weightTotals {
  margin-top: -20px !important;
}

.misMatched,
.misMatchedPort {
  color: red !important;
  font-weight: 600;
}

#exporterAddress,
#addressArea {
  margin: 0px;
  margin-bottom: -20px;
}

.siWrapper {
  border-radius: 3px;
  background-color: white;
  margin-top: 10px;
}

#exportInstructions {
  min-height: 70px !important;
  width: calc(100% + 3px);
}

#internalNotes {
  margin-left: 0%;
  width: calc(100% + 5px);
  min-height: 70px !important;
}

#exportInstructionsLabel {
  font-size: .8rem;
}

.supplierSameAsShipper {
  margin-top: -35px !important;
  margin-right: -10px !important;
}

.cooMarginTop {
  margin-top: 20px !important;
  margin-left: 10px !important;
  background-color: white;
}

.partyCard {
  padding-left: 10px !important;
  padding-right: 0px !important;
  background-color: white;
  min-width: 100%;
  margin-bottom: 0px;
}

.supplierAndNotifyParty {
  margin-bottom: 0px;
}

.createdBy {
  margin-top: 0px !important;
  padding: 5px !important;
  margin-bottom: 10px !important;
  min-width: 102%;
  margin-left: -8px !important;
  max-height: 90px;
  background-color: #f3f3f3 !important;
}

.row>.createdBy {
  padding-top: 0px !important;
}

.descriptionOfGoods {
  padding-top: 0px !important;
  background-color: white;
}

.addressButton {
  margin: 0px;
}

.buttonDrop {
  margin-top: 20px;
}

.buttonHalfDrop {
  margin-top: 6px;
  margin-bottom: 0px;
}

.disabledRow {
  background-color: #f5f5f5 !important;
  border: none;
}

.addressBox {
  font-size: 1em !important;
}

.specificButtonDrop {
  margin-top: -15px !important;
}

.exporterAddressButton {
  margin-top: 20px;
  margin-bottom: 0px;
}

.consigneeAddressButton {
  margin-bottom: 0px !important;
}

.consigneeAddressButton>button,
.exporterAddressButton>button,
.supplierAddressButton>button,
.notifyAddressButton>button {
  box-shadow: 0 2px 2px #ccc;
}

.aliasButton {
  margin-bottom: 100px !important;
}


.onlyPartyButtons {
  border: none !important;
}

.siAddressButtonsWidth {
  width: 109.75px !important;
}

.siAliasButtonsWidth {
  width: 70.94px !important;
}

.siAliasButtonsWidth>button {
  box-shadow: 0 2px 2px #ccc !important;
}

.containerRow {
  background-color: whitesmoke;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100% !important;
  margin-bottom: 0px;
}

.addDeleteContainer {
  margin-top: -35px;
  margin-bottom: -10px;
}

.containerTableRow {
  margin-top: -20px;
}

.date-0 {
  margin-left: 1px !important;
}

.date-1,
.date-2 {
  margin-left: -2px !important;
}

.hideButton>button {
  box-shadow: none !important;
  color: darkgray !important;
  pointer-events: none;
  border: 1px solid darkgray;
  background-color: whitesmoke;
}

.resetButton {
  margin-left: -145px !important;
  margin-top: -5px !important;
  height: 25px;
}

#print-modal>.modal-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

#print-modal {
  overflow-y: auto;
}

.lf-address-list {
  padding-left: 10px;
  padding-top: 5px;
  border-radius: 10px;
  margin-right: 10px;
  float: left;
  min-width: 200px;
  min-height: 100px;
  cursor: pointer;
}

.lf-address-selected {
  border: 2px solid rgb(58, 92, 205) !important;
}

.fileCountSpinner {
  height: 15px !important;
  width: 15px !important;
  margin-left: 10px;
  margin-right: -10px;
  margin-bottom: -3px !important;
}


/* CONTAINERS STYLING */
.containerButton,
.containerCountInput {
  margin: 0px !important;
  margin-bottom: 5px !important;
}

.containerCountInput {
  width: 100% !important;
}

.byContainerTotalInput {
  width: 100%;
  color: black;
}

.containerButton {
  width: 100% !important;
}

.deleteButton {
  margin-left: 4px !important;
}

.customTotal {
  width: 100% !important;
}

.placeholder {
  display: none !important;
  color: #dfdfdf !important;
}

.originalNameHeader {
  margin-top: 0px;
}

.docOptions {
  height: 10px;
}

.csvDownload,
.copyContainers {
  padding: 0px;
  margin: 0px;
}

.csvButton {
  display: flex;
  flex-flow: flex-end;
  float: right;

}

.downloadCsvButton,
.copyContainersButton {
  margin: 0px;
  padding: 0px !important;
}

.copyContainersButton {
  display: none;
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
}

.invisibleButtonSection {
  width: 36.22px;
  height: 30px;
  margin-right: 10px !important;
  ;
}

.invisibleButtonSection:hover>.copyContainersButton {
  display: block;
  margin-right: 10px !important;
}

.exportButtonRow {
  position: relative;
  border-bottom-color: #f5f5f5;
}

/* INVOICE */

.commissionSection {
  border: 1px black solid;
}

.invoicePaid {
  color: green;
}

.checkInRedux {
  color: blue;
}

.stagesNotesInvoice {
  border: 1px solid black;
  padding: 10px;
  border-radius: 3px;
  font-weight: 500;
}

/* TABS CONTAINER */

#marksAndNumbers,
#groupedWeight,
#unitPrice,
#amount,
#termsOfPrice\:0,
#termsOfDelivery\:0,
#countryOfOrigin\:0,
#preferenceCode\:0,
#hsCode {
  background-color: white;
}

/* DOCS OVERVIEW */

.overviewCheckbox {
  width: 60px !important;
}

.stageInitials {
  font-size: 16px;
  margin-top: 5px !important;
  display: flex !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

#initialsBadge {
  border: 1px white solid;
  margin-bottom: 5px;
  display: flex !important;
  justify-content: center !important;
}

#closeoutInitialsBadge {
  color: black;
  border: 1px white solid;
  margin-bottom: 5px;
  display: flex !important;
  justify-content: center !important;
}

.proofed {
  color: #f57c00 !important;
}

.notComplete {
  color: black !important;
}

.bookingIcon:hover {
  color: #2196f3;
  cursor: pointer;
}

.badgeAndIconColumn {
  min-width: 20px !important;
  width: 10px;
}

.iconColumn {
  padding-top: 3px;
}

.contextMenuSelect {
  font-size: 10px !important;
  height: 20px;
  margin-left: 5px;
}

.assignButton {
  margin: 0px;
  padding: 5px;
  border-radius: 5px;
  border: 1px rgb(80, 80, 112) solid;
  box-shadow: 2px 2px black;
  cursor: pointer;
  color: rgb(0, 0, 0);
  padding-top: 2px;
  padding-bottom: 2px;
}

.assignButton:hover {
  color: white;
  background-color: rgb(0, 0, 0);
}

.contextList {
  text-align: center !important;
}

.cancelOption {
  text-align: center !important;
  background-color: white !important;
  width: 20px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 0px;
  padding: 0px !important;
  line-height: 15px !important;
  color: black !important;
  border-radius: 10px;
}

.cancelIcon {
  color: red;
}

.cancelWords {
  text-align: center !important;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.docResults:nth-child(odd) {
  background-color: rgb(213, 213, 213);
}

#overviewStagesModal,
#bookingAndSiStagesWindow {
  margin-top: -50px !important;
  background-color: rgb(255, 255, 210);
  width: 70vw;
  height: auto !important;
  max-height: calc(100vh - 20px) !important;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
  overflow-x: hidden;
  color: black;
  z-index: 100;
  overflow-y: auto;
}

.closeModalIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.stickyNoteButton {
  padding-left: 20px;
  margin-top: 10px !important;
}

.stickyNoteButtonTab {
  border: 1px solid black;
  height: 48px !important;
  margin-top: 0px !important;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
}

.stickyNoteButtonClicked {
  box-shadow: inset 0 0 10px #000000;
}

.stickyNoteButtonUnClicked {
  background-color: white;
}

.stickyNoteButtonClickedYellow {
  box-shadow: inset 0 0 10px #000000;
  background-color: #ffffd2;
}

.stickyNoteButtonUnClickedYellow {
  background-color: #ffffd2;
}

.stagesWindowSwitch {
  margin-top: -10px !important;
}

.flaggedForRevisionCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid red;
  background-color: red;
}

.stagesCheckbox,
.stagesCheckbox:hover,
.stagesCheckbox:active,
.stagesCheckbox:focus,
.stagesCheckbox:target,
.stagesCheckbox::selection {
  color: black;
  font-size: 20px !important;
}

.stagesWindowHeader {
  text-align: center;
  font-size: 18px;
  margin-top: -10px;
  margin-bottom: -10px;
  overflow: hidden;
}

.stickyNoteRow {
  margin-top: -10px;
}

.slimRow {
  margin-top: -15px;
  margin-bottom: 5px;
}

.invisibleButton {
  height: 38px !important;
  margin-bottom: 10px !important;
}

.stagesWindowChecks {
  border-bottom: 1px solid black;
  padding-bottom: 15px !important;
  padding-top: 0px;
}

.stagesWindow>.col {
  padding: 30px;
  padding-bottom: 0px;
}

.lastNotesRow {
  padding: 10px;
  border: 1px solid black;
}

.lastInnerNotesRow {
  margin-top: -10px;
  margin-bottom: 0px;
}

.dateAndName {
  margin-top: 10px !important;
  font-weight: 700;
}

.generateButtonIcon>button {
  background-color: black !important;
  width: 1px !important;
  height: 1px !important;
  margin-right: -100px !important;
  margin-left: -50px !important;
}

.generateButton>button {
  width: 85px !important;
}

/* PARTY PAGES */

.tabs .tab a {
  color: #000;
}

.tabs {
  position: relative !important;
}

.filesTab {
  position: absolute;
  left: 180px !important;
  font-weight: 400;
}

.bookingAndSiTabs>li {
  border-right: 1px solid rgb(208, 208, 208);
}

.siAndBookingTabs>li {
  border-left: 1px solid rgb(208, 208, 208);
}

.bookingAndSiTabs,
.siAndBookingTabs {
  margin-bottom: 0px !important;
  border-bottom: 1px gray solid;
  padding-bottom: 1px;
}

.bookingAndSiTabs>div>ul>li,
.siAndBookingTabs>div>ul>li {
  border-radius: 5px !important;
  border-left: 1px black solid;
  border-top: 1px black solid;
  margin-top: 0px !important;
  z-index: 999 !important;
}

.bookingAndSiTabs>div>ul>li:last-child,
.siAndBookingTabs>div>ul>li:last-child {
  border-right: 1px black solid;
}

.bookingAndSiTabs>div>ul>li>a,
.siAndBookingTabs>div>ul>li>a {
  margin-top: -6px !important;
}

@media only screen and (max-width: 1000px) {

  .bookingAndSiTabs,
  .siAndBookingTabs {
    border-bottom: 0px gray solid;
  }

  .bookingTabs,
  .siTabs {
    border-bottom: 1px gray solid !important;
  }
}

.tabs .tab a:hover {
  background-color: #eee;
  color: #4890dd;
}

.tabs .tab.disabled a,
.tabs .tab.disabled a:hover {
  color: rgba(102, 147, 153, 0.7);
}

.tabs .tab a.active {
  color: #33679e;
}

.tabs .indicator {
  background-color: #33679e;
}

.contactsTableHead {
  background-color: #f3f3f3 !important;
}

.contactsTable {
  line-height: 0px;
}

.contactsTable:nth-child(even) {
  background-color: #f3f3f3 !important;
}

.currentParty,
.connection>td {
  font-size: 15px !important;
}

.addConnection,
.clientConnections {
  padding-top: 20px !important;
}

.addConnectionTable {
  margin-top: 20px !important;
}

.addConnectionTable>thead>tr,
.addConnectionTable>tbody>.bottomRow {
  border: none;
}

/* CUSTOMER OVERVIEW */

.customerDocDate {
  color: green;
}

.firstCargoDate>td {
  border-top: 2px dashed grey !important;
}

.customer-portal-table {
  border-collapse: collapse !important;
  border-spacing: 0;
  text-align: left;
  display: inline-block;
}

/* .customer-portal-tbody>tr{  */
/* padding: 10px!important;
  padding-left: 100px!important;
  border-bottom: 2px dotted grey!important;  */
/* }  */

/* .customer-portal-table>thead>tr>th{
  margin-left: 10px!important;
  margin-bottom: 20px!important;
} */

/* SHIPMENT OVERVIEW */


.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.selectable {
  user-select: none;
  -moz-user-select: text;
  -webkit-user-select: text;
}


.docsDueTableRow:hover>.hiddenMenu>.hiddenMenuTextArea>.hideNotes {
  display: none !important;
}

.hiddenMenuTextArea {
  display: none !important;
}

.hiddenMenu {
  height: 100% !important;
  position: relative !important;
}

.hiddenMenuButtons {
  display: none !important;
  overflow: visible;
  margin-left: 10px !important;
}

.docsDueTableRow>td {
  min-height: 24px !important;
}

.docsDueTableRow:hover>.hideOnHover {
  color: #eee;
}

.menu-selected:hover>.hideOnHover {
  color: rgb(255, 174, 69) !important;
}


.docsDueTableRow:hover>.hiddenMenu>.hiddenMenuTextArea {
  display: block !important;
  width: 500px !important;
  position: absolute;
  top: -50px;
  right: 125px;
  z-index: 100;
}

.docsDueTableRow:hover>.hiddenMenu>.hiddenMenuButtons {
  display: flex !important;
  flex-direction: row !important;
  height: inherit !important;
  margin-left: 10px !important;
  position: absolute;
  top: 0px;
  right: 10px;
}

.lf-portal-tbody>tr.menu-selected>.hiddenMenu>.hiddenMenuButtons {
  color: white;
  background-color: rgb(255, 174, 69) !important;
}

.exdecHiddenButtonStyle {
  height: 70% !important;
  width: 30px;
}

.lastSentTime {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 700;
  font-size: 14px;
  width: max-content;
}

@media only screen and (max-width: 900px) {
  .lastSentTime {
    font-size: 10px;
    width: 50px;
    margin-right: -25px;
  }
}

.heightParent {
  height: inherit;
}

.hiddenMenuButton {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.siNotesButtonIcon {
  text-align: center !important;
  margin-right: 10px;
}

.docsDueInternalNotes {
  min-height: 55px !important;
  max-height: 55px !important;
  background-color: #3f3f3f !important;
  color: white !important;
  border-radius: 10px 10px 0px 10px;
}

.docsDueInternalNotesLabel {
  background-color: #3f3f3f !important;
  color: white !important;
  border-radius: 5px !important;
}

.chartButtonIcon {
  margin: 0px !important;
  padding: 0px !important;
}

.chartButton {
  margin-bottom: 12px;
  padding-right: 20px !important;
}

.chartButton>button {
  padding-left: 3px;
  padding-right: 2px;
  display: flex;
  justify-content: center;
}

.chartButtonPressed>button,
.chartButtonPressed:active>button {
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: black !important;
  background: #e4e4e4 !important;
  box-shadow: inset 0px 0px 7px #979797 !important;
  -mox-box-shadow: inset 0px 0px 5px #727272 !important;
  -webkit-box-shadow: inset 0px 0px 5px #424141 !important;
  outline: none !important;
  border: 2px inset rgb(175, 175, 175) !important;
}

.dateSearchButtons>button {
  padding-right: 10px;
}

.dateSearchButtonsArrowBack>button {
  padding-right: 5px;
  padding-left: 11px;
}

.dateSearchButtonsRight {
  padding-right: 0px;
}

.dateSearchButtons,
.dateSearchButtonsArrowBack {
  padding: 0px !important;
  display: flex !important;
  justify-content: space-around !important;
}

.dateSearchButtons>button,
.dateSearchButtonsArrowBack>button {
  padding: 0px !important;
  text-align: center !important;
  max-width: 20px !important;
}

.dateSearchButtons>button>i,
.dateSearchButtonsArrowBack>button>i {
  text-align: center !important;
  padding-left: 0px !important;
  margin: 0px !important;
  width: 15px;
}

.dateSearchButtonContainer {
  padding-left: 0px !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 0px !important;
  height: 30px !important;
}


@media screen and (max-width: 1500px) {

  .dateSearchButtons>button,
  .dateSearchButtonsArrowBack>button {
    max-width: 50px !important;
  }

  .dateSearchButtons,
  .dateSearchButtonsArrowBack {
    padding: 0px !important;
    max-width: 200px;
  }
}

.overviewFilterButtonsContainer {
  margin: 0px;
  padding: 0px !important;
  padding-right: 12px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.overviewFilterButtonsContainer>div {
  padding: 0px !important;
  margin-bottom: 0px;
}

.overviewFilterButtonsContainer>div>button>i {
  margin-left: -6px !important;
}

.dateBordersLeft {
  border-left: 1px solid black;
}

.dateBordersRight {
  border-right: 1px solid black;
}

/* REPORTS PAGE */

#commissionReportTable {
  font-size: 12px;
}

#commissionReportTable>thead>tr {
  background-color: #33679e;
  color: white;
}

#commissionReportTable>thead>tr>th {
  text-align: left;
}

#commissionReportTable>tbody>tr:nth-child(even) {
  background-color: rgb(226, 226, 226);
}

.commissionAverage {
  font-weight: 700;
}

.activeTab,
.activeTab>a,
.activeTab:active,
.activeTab:focus {
  background-color: #33679e !important;
  color: white !important;
  border-radius: 14px 14px 0px 0px;
}

.activeBookingTab,
.activeBookingTab>a,
.activeBookingTab:active,
.activeBookingTab:focus,
.activeSiTab,
.activeSiTab>a,
.activeSiTab:active,
.activeSiTab:focus,
.activeUploadTab,
.activeUploadTab>a,
.activeUploadTab:active,
.activeUploadTab:focus {
  background-color: #33679e !important;
  color: white !important;
  border-radius: 14px 14px 0px 0px;
}

.activeBookingTabPrinted,
.activeBookingTabPrinted:active,
.activeBookingTabPrinted:focus,
.activeSiTabPrinted,
.activeSiTabPrinted:active,
.activeSiTabPrinted:focus,
.activeUploadTabPrinted,
.activeUploadTabPrinted:active,
.activeUploadTabPrinted:focus,
.activeUploadTabPrinted:focus-visible {
  background-color: #0b9148 !important;
  border-radius: 14px 14px 0px 0px;
}

.activeBookingTabPrinted>a,
.activeSiTabPrinted>a,
.activeUploadTabPrinted>a {
  color: white !important;
}

.inactiveBookingTabPrinted,
.inactiveBookingTabPrinted:active,
.inactiveBookingTabPrinted:focus,
.inactiveSiTabPrinted,
.inactiveSiTabPrinted:active,
.inactiveSiTabPrinted:focus .inactiveUploadTabPrinted,
.inactiveUploadTabPrinted:active,
.inactiveUploadTabPrinted:focus {
  background-color: white !important;
  font-weight: 600;
  border-radius: 14px 14px 0px 0px;
}

.inactiveBookingTabPrinted>a,
.inactiveSiTabPrinted>a,
.inactiveUploadTabPrinted>a {
  font-weight: 600;
  color: #0b9148 !important;
}

.activeUploadTabUseNotes>a {
  color: black !important;

}

.flagIconHolder {
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
}

.vesselVoyageBookingCountRow>div {
  text-align: center;
  font-weight: 600;
}

.bookingTabs .indicator,
.siTabs .indicator {
  display: none;
}

/* CONTRACTS */

.progress {
  height: 15px !important;
  text-align: right;
  padding-right: 5px !important;
  color: white;
  z-index: 100;

  background-color: #aecef0;
}

.determinate {
  background-color: #33679e !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
  margin-top: -4px;
  vertical-align: text-top !important;
}

.stepStatus {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px !important;
}

.lfManagedDetails {
  margin-bottom: 12px;
}

.contractsSidebarTest {
  background-color: red;
  position: absolute;
  left: -30px;
  z-index: 4000 !important;
}

.contractEditHeader {
  height: 190px;
  margin: 0px;
  padding: 0px;
  padding-top: 25px;
  font-size: 14px;
}

.originDropdownIcon:focus {
  border-radius: 5px;
  border: 2px black solid !important;
}

.lastActiveFreetime {
  background-color: #d5eaff;
}

/* FILE UPLOAD */

.hideTab {
  display: none !important;
}

.moverModal {
  width: 50%;
  height: max-content;
}

.moverModal>.modal-content>.lineBreak {
  border: 1px solid rgb(201, 201, 201) !important;
  margin-bottom: 10px;
}

.moverModal>.modal-content {
  margin-bottom: 0px !important;
}

.noFilePlaceholder {
  height: 200px;
  font-style: italic;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ;
}

#awsPdfViewer>.modal-content>h5 {
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: center;
}

#awsPdfViewer>.modal-content>.row>.col>span {
  font-weight: 600 !important;
}

#awsPdfViewerDisplayWindow {
  width: 585px;
  height: 500px;
}

#fileViewerModal>.modal-content {
  height: 700px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

#fileUpload {
  display: block
}

#fileUploadContainer {
  height: 82vh;
  overflow: hidden !important;
  display: block;
  width: 100%;
  position: relative !important;
}

.fileListSection {
  height: 72vh;
  overflow-y: scroll !important;
  padding-top: 10px;
}

#fileTable>thead>tr>th {
  padding-top: 0px !important;
}

#fileTable>tbody>tr>td {
  padding: 0px !important;
}

#fileTable>tbody>tr>td>div>div>p>label {
  padding-left: 10px !important;
}

#fileTable>tbody>tr {
  padding: 0px 0px 0px 8px !important;
}

#fileTable>tbody>tr:hover {
  background-color: rgb(45, 40, 40);
  color: white;
  margin-bottom: 100px !important
}

.activeFileRow:hover {
  background-color: black;
  color: white;
}

.hiddenFileRow {
  color: gray !important;
  background-color: rgb(234, 231, 231) !important;
}

.hiddenFileRow:hover {
  background-color: rgb(111, 111, 111) !important;
  color: white !important;
}

.hiddenRowContainer {
  position: relative;
  height: 30px;
  background-color: lightgray;
  color: black;
  width: 400px !important;
}

.hiddenRowMessage {
  margin-top: 5px;
  position: absolute;
  left: 50%;
}

.oldFileRow:hover {
  background-color: rgb(196, 196, 196) !important;
  color: black !important;
}

#uploadButton {
  display: none;
}

.hiddenCollapsible {
  padding-bottom: 10px;
  text-align: left !important;
  padding-left: 25px;
  padding-top: 10px;
}

.hiddenCollapsible>span {
  padding-top: 20px !important;
}

.dragDetector {
  width: 100vw;
  height: 100vh;
  opacity: 0.1 !important;
  position: absolute;
}

.fileUploadIconHolder {
  display: flex;
  flex-direction: column;

}

.spinnerHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.waitingForDrop {
  background-color: rgb(222, 222, 222) !important;
  opacity: .8;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgb(255, 252, 255) 50%, transparent 50%), linear-gradient(90deg, rgb(255, 255, 255) 50%, transparent 50%), linear-gradient(0deg, rgb(255, 255, 255) 50%, transparent 50%), linear-gradient(0deg, rgb(255, 255, 255) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  animation: border-dance 8s infinite linear;
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  z-index: 10;
  top: 0px;
  left: 0px;
}

.notWaitingForDrop>div>i,
.notWaitingForDrop>div>span {
  display: none;
  color: red !important;
}


.waitingForDrop * {
  user-select: none !important;
  -webkit-user-select: none;
  pointer-events: none !important;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  cursor: default;
  z-index: 0 !important;
}

.notWaitingForDrop {
  height: 100vh;
  opacity: 1 !important;
  position: absolute;
  pointer-events: none !important;
}

.arrowIcon {
  position: absolute;
  text-align: left;
  left: 0px;
  top: 8px;
}


@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }

  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}


.newFile {
  background-color: white;
  animation-name: firework;
  animation-duration: 1s;
}

@keyframes firework {
  0% {
    background-color: #33679e;
    color: white;
  }

  15% {
    background-color: #457ab3;
    color: #eff4f9;
  }

  25% {
    background-color: #5287bf;
    color: #d9e5f1;
  }

  35% {
    background-color: #669bd3;
    color: #c3d8ef;
  }

  45% {
    background-color: #76a6d8;
    color: #a8c8eb;
  }

  55% {
    background-color: #8fb6df;
    color: #76a6d8
  }

  65% {
    background-color: #a8c8eb;
    color: #5287bf;
  }

  75% {
    background-color: #c3d8ef;
    color: #457ab3;
  }

  85% {
    background-color: #d9e5f1;
    color: #33679e;
  }

  95% {
    background-color: #eff4f9;
    color: black;
  }

  100% {
    background-color: #ffffff;
    color: black;
  }
}

.file-upload-context-menu>ul>li {
  color: black;
  padding: 10px !important;
  cursor: pointer;
}

.file-upload-context-menu,
.file-upload-context-menu>ul {
  z-index: 1000 !important;
}

.file-upload-context-menu>ul>li:hover {
  background-color: rgb(235, 234, 234);
}

.attachmentListIcons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.attachmentList {
  border: none !important;
}

.attachmentListItem {
  border: 1px solid darkblue;
  background-color: rgb(188, 188, 188);
  color: white;
  border-radius: 5px;
  margin-bottom: 5px;
}

.fileSizeWarning {
  font-size: 20px;
  color: rgb(132, 78, 78);
}

.uploadQuote {
  font-size: 18px;
  color: black;
  text-align: center;
  font-style: italic;
}

.uploadScreenTip {
  position: fixed;
  bottom: 100px;
  left: 50%;
  margin-left: -150px;
  display: flex !important;
  flex-direction: row !important;
  ;
  justify-content: center !important;
  font-style: italic;
}

.quoteContainer {
  margin-top: 20px;
}

/* USERS OVERVIEW */

.user-portal-table {
  font-weight: 400;
  font-size: 0.9rem;
  color: white;
}

.user-portal-table>tbody>tr {
  color: black;
  font-size: 0.8rem;
}

.user-portal-thead {
  background-color: #33679e;
}

.userEditCheckBox>div>p>label,
.userEditCheckBox>div>p,
.userEditCheckbox>div,
.userEditCheckbox {
  width: 100%;
}

.userEditCheckbox>div,
.userEditCheckbox>div:hover,
.userEditCheckbox>div:active {
  width: 100%
}

/* VESSEL SCHEDULE */

.sche_field_name {
  background-color: #0577B3;
  color: white;
}

.sche_field_odd {
  background-color: #EAEAEA;
  border-top: 1px solid #bdbdbd;
}

.sche_field_even {
  background-color: #C9ECFE;
}