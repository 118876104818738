.div {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.input {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.input.err {
    border: 1px solid #f44336;
}

.input.err:hover {
    border: 1px solid #f44336;
}

.input.err:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.input:hover {
    border: solid 1px black;
}

.input:focus {
    padding: 0px 0px 0px 4px;
    border-color: transparent;
    box-shadow: 0 0 0 2px #2196f3;
}

.label {
    height:16px;
    margin: 0px;
    padding: 0px 0px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.msg {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.disabled{
    background-color: #eee;
}
