
.lf-portal-table {
    background-color: white;
    font-weight: 400;
    font-size: .9em;
    margin-top: 1px;
 
}

.lf-portal-thead {
    background-color: #33679e;
    color: white;
    max-height: 40px;
    line-height: 1;
}

.lf-portal-thead>tr>th {
    position: relative;
    border-radius: 0px;
    text-align: center;
    font-size: 1em;
    max-height: 40px;
}

.lf-portal-tbody>tr>td {
    border-radius: 0px;
    text-align: left;
    
}

td, tr{
    padding: 4px;
}

tbody>tr:hover{
    text-shadow: #33679e;
    box-shadow: #33679e;
    background-color: #f0f3f7
}

.lf-portal-tbody>tr {
    margin: 2px;
    border: 1px solid white;
    
}

.lf-portal-tbody>tr:hover {
    background-color: #eee;
}

.lf-portal-tbody>tr.selected {
    color: white;
    background-color: #2196f3;
    border: 1px solid white;
}

.lf-portal-tbody>tr.menu-selected {
    color: white;
    background-color: rgb(255, 174, 69);
    border: 1px solid white;
}

.lf-portal-tbody>tr>td {
    margin: 0px;
    padding: 0px;
    padding: 5px 5px 5px 12px;
}

.lf-shipment-context-menu>ul>li:hover {
    background-color: #ddd;
}

.lf-portal-tbody>tr>td.no-margin-no-padding {
    margin: 0px;
    padding: 0px;
    width: 1px !important;
}

.lf-portal-tbody>tr>td.no-margin-no-padding>span.badge {
    margin: 4px;
    padding: 0px;
}

span.badge.new:after {
    content: none;
}