.lf-sidebar-toggle {
    position: fixed;
    bottom: 6px;
    left: 6px;
    background-color: white;
    cursor: pointer;
    z-index: 3000;
}

.lf-sidebar-toggle>.material-icons {
    color: black;
    font-size: 2.2em;
}

.lf-sidebar-toggle:hover {
    background-color: black;
}

.lf-sidebar-toggle>.material-icons:hover {
    color: white
}

.sideLink {
    font-size: 13px;
    padding-top: 7px!important;;
    padding-bottom: 7px!important;
}

.sideLink:hover {
    background-color: rgb(236, 236, 236);
    color: white;
}

a{
    text-decoration: none;
}

a:hover {
    text-emphasis: unset;
}

.active-link {
    box-shadow:inset 100px 100px 100px 1000px #33679e,
    0 4px 8px 10px rgba(109, 109, 109, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.19) !important;
}

.active-link>a{
    color:white;
    font-weight: 700;
}

#masterSearch{
    margin-left:5px;
    margin-right:0px;
}

.search-wrapper{
    margin-top: 15px;
    margin-right:0px;
}

.carrierListTabs{
    cursor: pointer;
    padding: 5px;
    padding-left: 25px!important;
    font-size: 10px;
    text-align: left;

}

.carrierListTabs:hover{
    background-color: rgb(168, 167, 167);
}