.div {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.textarea {
    position: relative;
    margin: 0px;
    padding: 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    /* -webkit-box-shadow: inset 0px 0px 27px -23px rgba(0,0,0,0.38);
    -moz-box-shadow: inset 0px 0px 27px -23px rgba(0,0,0,0.38);
    box-shadow:  0px 0px 27px -23px rgba(0,0,0,0.38); */
    border-radius: 3px;
    height: 36px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
    resize: none;
}

.textarea:hover {
    border: solid 1px black;
}

.textarea:focus {
    border: 2px solid #2196f3;
    padding: 5px;
}

.label {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}
