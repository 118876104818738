.div {
    padding-right: 0px !important;
    position: relative;
    display: flex;
    flex-direction: row;
}

.container {
    flex: 11;
    max-width: 100%;
    position: relative;
}

.open {
    background-color: white;
    width: calc(100% - 12px);
    border: solid 1px #ddd;
    z-index: 100;
    position: absolute;
    height: auto !important;
}

.list {
    padding: 6px !important;
    height: auto !important;
    display: block;
    overflow-y: auto;
    position: relative !important;
}

.smallFormList {
    font-size: 12px !important;
    height: auto !important;
}

.input {
    position: relative;
    margin-top: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 22px !important;
    outline: none;
    font-size: .8rem;
    width: 100%;
}

.smallFormInput {
    font-size: 12px !important;
    height: 20px !important;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-bottom: 0px solid black;
    border-radius: 3px;
    outline: none;
    width: 100%;
}

.input.err {
    border: 1px solid #f44336;
}

.input.err:hover {
    border: 1px solid #f44336;
}

.input.err:focus {
    border: 2px solid #f44336;
    padding: 0px 0px 0px 4px;
}

.input:hover {
    border: solid 1px black;
}

.input:focus {
    outline: 1px solid #2196f3 !important;
    box-shadow: 0px 0px 0px 1px #2196f3 inset;
}

.label {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.msg {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.item {
    padding-top: 8px;
    height: 18px;
    font-size: 1rem;
    left: 6px;
}

.highlighted {
    background-color: #2196f3;
    color: white;
}

.add {
    margin: 0px;
    padding: 0px;
    color: #2196f3;
    background-color: white;
    font-size: 32px;
    border-radius: 6px;
    flex: 1;
}

.add:focus {
    background-color: white;
    color: #2196f3;
    border: #2196f3 1px solid;
    margin-left: 3px;
}