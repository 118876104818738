#bookingRequestModal {
    width: 70%;
    margin-bottom: 0px;
    overflow-y: auto;
    border-radius: 10px;
}

.bookingRequestModalBody {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid black;
    background-color: #ffffcc;
    border-radius: 5px;
}

.shipperViewText {
    color: #2a7e19;
}

.saveAndEditButton, .saveAndEditButton:hover {
    position: absolute;
    left: 1px;
}

.closeIcon {
    float: right;
    margin-bottom: 0px!important;
}

.noMarginBottom { 
    margin-bottom: 0px!important;
}

.smallMarginBottom {
    margin-bottom: 10px!important;
}

.modalHeader {
    margin-top: 0px;
    text-align: center;
    font-size: 22px;
}

.userSection {
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 10px!important;
    margin-bottom: 0px;
    margin-top: -10px;
}

.commentsRow > h6 {
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    margin-bottom: 8px;
}

.commentTextArea > textarea{
    min-height: 30px!important;
}

.noteRow {
    min-height: 22px!important;
    position: relative;
    margin-bottom: 0px;
}

.noteContainer{
    display: flex;
    flex-direction: column;

}

.noteSpan {
    font-weight: 300;
    white-space: pre;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.noteLabel {
    font-style: italic!important;
    color: #9e9e9e!important;
    font-size: .8em;
    font-weight: 300;
}

.addCommentRow {
    margin-bottom: 0px;
}

.commentBox {
    margin-bottom: 10px;
}

.commentHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.commentName {
    margin-right: 10px;
    font-weight: 500;
    color: grey;
    font-size: 14px;
}

.commentTime {
    font-size: 12px;
    padding-top: 3px;
    font-weight: 500;
    color: grey;
}

.commentBody {
    font-size: 16px;
    margin-left: 1em!important;
}

.requestDates {
    margin-bottom: 15px;
}

.datetimeRequestedDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
}

.datetimeAdvancedDiv {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.datetimeCompletedDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: right;
}

.alignRight {
    text-align: right;
}

.requestDates > div >  label {
    font-style: italic;
}

.requestDates > div > span {
    font-size: 14px;
}

.contractInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.specialRestrictionNote {
    background-color: rgb(255, 220, 155);
    padding: 10px;
    border-radius: 5px;
    color:rgb(172, 95, 0);
}

.specialRestrictionLabel {
    font-weight: 500;
}


.bookingNumberLabel {
    margin-top: -12px!important;
    position: absolute;
    font-style: italic;
}

.bookingNumberSpan {
    position: absolute;
}

.requestProgressContainer {
    background-color: rgb(166, 228, 156)!important;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    position:relative;
}

.requestProgress {
    height: 16px!important;
    padding: 0px!important;
    margin: 0px!important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
}

.statusMessage {
    padding-bottom: 1px!important;
}

.requestView {
    background-color: rgb(255, 255, 255)!important;
    border:rgb(120, 111, 115);
    color: rgb(130, 130, 130)!important;
}

#templateModal {
    width: 40%;
    border-radius: 8px;
}

.templateListRow {
    font-weight: 400;
    border-radius: 4px;
    padding-top: 5px;
    box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.5);
}

.templateListRow > span > i {
    color: red;
}

.progress div {
    display: block;
    height: 100%;
}

.NEW, .REQUESTED, .PENDING, .DECLINED, .COMPLETED, .CLOSED, .UPDATING, .DELETED {
    font-weight: 700;
}

.NEW {
    color:seagreen;
}

.NEWdeterminateContainer {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.NEWdeterminate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      rgb(75, 145, 75) 1rem,
      rgb(75, 145, 75) 2rem
    );
    background-color: rgb(37, 228, 37)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}


.PENDING {
    color: #329ef4;
}

.PENDINGdeterminateContainer {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.PENDINGdeterminate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    animation: progressBar 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.UPDATING {
    color: #329ef4;
}

.UPDATINGdeterminateContainer {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.UPDATINGdeterminate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    animation: progressBar 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}


.DECLINED {
    color: #f44336;
}

.DECLINEDdeterminateContainer {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.DECLINEDdeterminate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem,
      transparent 1.7rem,
      rgb(201, 55, 55) 1rem,
      rgb(201, 55, 55) 2rem
    );
    background-color: #f44336!important;
    background-size: 200% 200%;
    animation: progressBar 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.COMPLETED {
    color: rgb(130, 130, 130);
}

.COMPLETEDdeterminateContainer {
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.COMPLETEDdeterminate {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(211, 211, 211) 1rem,
      rgb(211, 211, 211) 2rem
    );
    background-color: rgb(130, 130, 130)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.CLOSED {
    color: black;
}

.CLOSEDdeterminateContainer {
    margin: 200px;
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.CLOSEDdeterminate {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(120, 120, 120) 1rem,
      rgb(120, 120, 120) 2rem
    );
    background-color: rgb(0, 0, 0)!important;
    background-size: 200% 200%;
    animation: progressBar 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.REQUESTED {
    color: rgb(255, 140, 0);
}

.REQUESTEDdeterminateContainer {
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.REQUESTEDdeterminate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(177, 138, 69) 1rem,
      rgb(177, 138, 69) 2rem
    );
    background-color: rgb(255, 140, 0)!important;
    background-size: 200% 200%;
    animation: progressBar 20s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}


.statusContextMenu, .statusContextMenu > ul {
    z-index: 5000!important;
    background-color: red !important;
    overflow: visible!important;
    cursor: pointer;
}

.loveTables {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.loveTables td {
    border: 1px solid black;
    white-space: pre;
    border-collapse: collapse;
}

.loveTables  tr {
    border-bottom: none!important;
}

.centerTable {
    display: flex!important;
    justify-content: center!important;
    flex-direction: row!important;
}

.toolTipCommentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 300px;
}

.toolTipCommentAuthor {
    text-align: left;
    font-style: italic;
}

.toolTipComment {
    text-align: left;
}

.defaultEmptyRow {
    min-height: 40px!important;
}

.buttonRow{ 
    max-height: 10px!important;
    padding-bottom: 10px!important;
    margin-bottom: -20px!important;
}

.reminderButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.buttonRow > div {
    margin-bottom: 10px!important;
}

.reminderRow {
    border: 1px solid black;
    border-radius: 5px;
    position: relative;
    height: 30px;
    max-height: 30px;
    padding-top: 2px;
    width: 102%
}

.reminderRow > label {
    position: absolute;
    top: -10px;
    left: 4px;
    background-color: white;
}

.unread {
    background-color: rgb(255, 227, 136);
}

.requiresAttention {
    background-color:rgb(173, 173, 255);
}

.showAllContractsRow:hover {
    cursor: pointer;
}

.showAllContractsRow {
    cursor: pointer;
    text-decoration: underline;
}

  .disabledStyle > button {
      background-color: rgb(211, 211, 211)!important;
      color:rgb(184, 184, 184)!important;
      border: 1px solid white!important;
  }

  .disabledButtonToolTip .disabledButtonToolTipText {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 100px;
    bottom: 175px;
    z-index: 4000!important;
    border-radius: 0px 15px 15px 15px;
    border: 1px solid black;
    width: 200px;
  }
  

  .disabledButtonToolTip:hover .disabledButtonToolTipText {
    visibility: visible;
  }

  .containerCountToolTip .containerCountToolTipText {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    text-align: left;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-size: 12px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 300px;
    top: 155px;
    z-index: 4000!important;
    border-radius: 15px 15px 15px 0px;
    border: 1px solid black;
    width: 400px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .containerCountToolTip > .containerCountToolTipText {
    background-color: rgb(211, 211, 211)!important;
    color:rgb(184, 184, 184)!important;
    border: 1px solid white!important;
  }

  .containerCountToolTip:hover .containerCountToolTipText {
      visibility: visible;
  }

  .newRequestHeaderClose > .collapsibleHeaderForms {
    opacity: 0;
    animation: disappear .5s;
  }

  .newRequestHeaderOpen > .collapsibleHeaderForms {
      display: flex;
      animation: appear .5s;
  }

  .collapsibleHeaderForms {
      display: flex;
      justify-content: flex-end;
      float: right!important;
      text-align:right!important;
      width: 100%;;
  }

  .newRequestHeaderOpen {
    margin-bottom: 20px!important;
    display: flex;
    justify-content: space-between;
  }

  .newRequestHeaderClose {
    height: 26px!important;
    margin-bottom: 20px!important;
    display: flex;
  }

  .newRequestHeaderOpen > p, .newRequestHeaderClose > p {
      cursor: pointer;
  }

  .newRequestForm {
    border-radius: 10px;
    background-color: rgb(237, 237, 237);
    padding-left: 22px!important;
    margin-bottom: 10px!important;
    display: block;
  }

  .openForm {
      animation-name: collapsible;
      animation-duration: .5s;
      padding: 10px;
      padding-top: 15px!important;
      overflow: visible;
  }

  .closeForm {
    animation-name: collapsible-close;
    animation-duration: .5s;
    height: 0px;
    margin: 0px!important;
    border: none!important;
    overflow:hidden!important;
  }

  .containerCountsSpan {
    color: black;
  }

  .bookingRequestForm {
      border-bottom: 1px solid rgb(0, 0, 0);
      margin-bottom: 10px;
  }

  .italicComment {
      font-style: italic;
  }

  .saveReminderButton {
      padding: 0px;
      margin: 0px;
      color: black;
      height: 30px;
  }

  .saveReminderButton:focus {
    border: 1px solid rgb(38, 186, 255);
    border-radius: 4px;
    background-color: white;
    color: black;
  }

  .statusDisplay {
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    height: 30px;
    width: calc(16.6666666667% - 12px)!important;
    padding-top: 4px!important;
    margin-right: 12px!important;
    position: relative!important;
  }

  .statusDisplayLabel {
    position: absolute;
    top: -9px;
    left: 2px;
    background-color: white;
    font-weight: 400!important;
  }

  .editRequestIndex {
    box-shadow:inset 0px 0px 0px 1px rgb(0, 0, 0)!important;
  }

  .notificationIcon {
    width: 5px!important;
} 

  .notificationIcon > i {
    padding-top: 4px!important;
    transform: scale(.8);
}

.statusMemoColumn {
    min-width: 200px;
}

  @media screen and (max-width: 1200px) {

    .containerCountsSpan{
        font-size: 10px;
    }
  }

  @keyframes collapsible {
      from { 
          height: 0px;
          padding: 0px; 
          overflow: hidden;
      }
      to {
          height: 214px;
          padding: 10px;
          overflow: visible;
      }
  }

  @keyframes collapsible-close {
      from {
          height: 214px;
          padding: 10px;
          border: 1px solid black!important;
      }
      to {
          padding: 0px!important;
          height: 0px;
          border: none;
      }
  }

  @keyframes appear {
      from {
          display: none;
          opacity: 0;
      }
      to {
          display: flex;
          opacity: 1;
      }
  }

  @keyframes disappear {
      from {
          display: flex;
          opacity: 1;
      }
      to {
          opacity: 0;
      }
  }

