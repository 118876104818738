.tabs{
    position: relative;
    top: 1px;
    height: 35px;
}

.tab{
    margin-top: -7px !important; 
}

.customTabs > .tab > a {color: #2196f3; }

.customTabs > .tab > a:hover{color: #2196f3}

.customTabs > .tab:hover{background-color: #eee}

.customTabs > li.active > a{background-color: #dddddd; color: rgb(0, 0, 0);}

.tab-close{
    position: relative;
    top: 12px;
}