.div {
    margin-top: 2px !important;
    padding: 0px 6px 0px 0px !important;
    position: relative;
}

.input {
    position: relative;
    margin: 0px;
    margin-top: -10px !important;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 22px;
    outline: none;
    font-size: .8rem;
    width: 100%;
}

.input.err {
    border: 1px solid #f44336;
}

.input.err:hover {
    border: 1px solid #f44336;
}

.input.err:focus {
    border: 2px solid #f44336;
    padding: 0px 0px 0px 4px;
}

.input:hover {
    border: solid 1px black;
}

.input:focus {
    border: 2px solid #2196f3;
    padding: 0px 0px 0px 4px;
}

.label {
    margin: 0px;
    padding: 0px 0px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -6px;
    height: 13px;
    background-color: white;
    font-size: .6rem;
}

.msg {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.disabled {
    background-color: #eee;
}