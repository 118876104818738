.section{
    position: relative;
    border-radius: 6px;
    padding: 24px 24px 4px 24px !important;
    /* margin-top: 15px !important; */
    margin-top: 19px;
}

.label{
    margin: 0px;
    padding: 0px;
    font-size:  23px;
    font-style: italic;
    position: absolute;
    z-index: 1;
    background-color: white;
    /* text-shadow: 2px 1px 3px rgb(255, 255, 255); */
    border-radius: 20px 20px 0px 0px;
    padding-left: 5px;
    padding-right: 5px;
    top: -16px;
    left: 12px;
    /* left: 0px; */
}