.contactSection {
    margin-bottom: 10px !important;
}

.addContactSpan {
    cursor: pointer;
    z-index: 999;
}

.vesselSection {
    padding-bottom: 24px !important;
}

.vesselSection>div>div {
    margin-bottom: 0px;
}

.vesselTableBody {
    display: flex;
    flex: 2;
}

.vesselCard {
    padding: 0px;
    max-width: CALC(50% - 12px);
    margin: 5px;
}

.vesselCard>div {
    padding: 0px !important;
}

.vesselCard>div>i {
    font-size: 22px;
    line-height: 30px;
}

.vesselDisplayContainer {
    max-height: 175px;
    height: 175px;
    overflow-y: scroll;
    padding: 8px;
    border: 1px inset rgb(234, 234, 234);
    border-radius: 3px;
}

.carrierOverviewTable {
    display: inline-block;
}